<template>
    <div class="content" id="introduce" style="margin:0 auto">
        <!-- 公司介绍 -->
        <div class="section mt1">
            <div class="center">
                <CornerTitle lt-text="01" rb-text="公司介绍" />
                <div class="section1">
                    <img src="../../assets/cover1.jpg" class="part1" />
                    <div class="part2">
                        <p class="font18">智君科技</p>
                        <p class="font14 indent">以创造伟大的产品为使命，帮助投资者做出正确的投资决策，实现金融投资目标。</p>
                        <p class="font14 indent">智君科技以智能投研算法和公募基金为出发点，填补国内基金投研和基金投顾起步阶段的数智化空白，瞄准老龄化下的个人养老金投资趋势，满足机构投研和投顾对专业化提升的恒久需求，提供下一代基金领域的大数据与云计算平台。</p>

                        <p class="font18">我们的产品</p>
                        <p class="font14 indent">智君科技的产品可以概括为一个数据库、两个平台、三类客户。</p>
                        <p class="font14 indent">一个投研数据库：以基金等资管产品为核心，从买方投资视角，搭建AI投研大数据；</p>
                        <p class="font14 indent">一个基金投研平台：以金融量化和金融AI为技术优势，以基金投资胜率提升为目标，提供基金研究和FoF管理的云计算平台；</p>
                        <p class="font14 indent">一个基金投顾平台：以基金知识图谱为基石，从投资者利益出发点，为线上理财和线下投顾提供AI资讯、基金研究、养老投资、客户沟通的数智化平台。</p>
                        <p class="font14 indent">三类客户：个人投资者、投资顾问和金融机构。</p>

                        <p class="font18">创新的投资研究</p>
                        <p class="font14 indent">我们秉承全球最大的基金服务机构——美国道富集团的基金投资研究框架，以团队在道富、高盛、德银、穆迪等顶尖国际金融机构的金融工程和金融AI技术积累为砖石，搭建国内自主品牌的投研数据和投研平台，替代美国晨星、美国明晟（MSCI Barra）在国内的市场份额。</p>
                        <p class="font14 indent">智君科技的核心优势是是领先的基金研究、高效的软件技术、和交互体验优异的产品设计。通过对投研数据和AI算法的持续投入，提供高性价比的客户服务。通过SaaS、本地部署的形式，以网页、IPad、企微、H5等产品形态，向多元化的投资者提供多样化的投研和投顾产品与服务。</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 企业文化 -->
        <div class="section mt2">
            <div class="center">
                <CornerTitle lt-text="02" rb-text="企业文化" />
                <div class="section2">
                    <div class="item">
                        <div class="circle">
                            <span class="word font52 b1">使</span>
                            <span class="word font52 b2">命</span>
                        </div>
                        <p>创造伟大的产品</p>
                        <p>帮助投资者实现</p>
                        <p>投资目标</p>
                    </div>
                    <div class="item">
                        <div class="circle">
                            <span class="word font52 b1">愿</span>
                            <span class="word font52 b2">景</span>
                        </div>
                        <p>成为全球资管行业</p>
                        <p>科技金融的领航者</p>
                    </div>
                    <div class="item">
                        <div class="circle">
                            <span class="word font36 s1">价</span>
                            <span class="word font36 s2">值</span>
                            <span class="word font36 s3">观</span>
                        </div>
                        <p>瑾守公正，保持专业</p>
                        <p>坚持创新，追寻挑战</p>
                        <p>技术为先，服务为本</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 公司优势 -->
        <!-- <div class="section mt3" id="advantage">
            <CornerTitle lt-text="03" rb-text="公司优势" />
            <div class="section3">
                <div class="item">
                    <div class="icon-box">
                        <img src="../../assets/about/icon1.png" alt="">
                        <p class="font18">科研能力</p>
                    </div>
                    <p class="font14">
                        经美联储和欧洲央行检验的全球系统性重要金融集团的内部量化模型研发技术和标准<br/>
                        全球顶尖高校硕、博投身于第三方金融定量分析服务
                    </p>
                </div>
                <div class="item">
                    <div class="icon-box">
                        <img src="../../assets/about/icon2.png" alt="">
                        <p class="font18">业务经验</p>
                    </div>
                    <p class="font14">
                        美国第七大金融集团和全球第三大资管公司的金融量化分析和风险管理经验<br/>
                        国内服务多家银行资管、券商和公募基金
                    </p>
                </div>
                <div class="item">
                    <div class="icon-box">
                        <img src="../../assets/about/icon3.png" alt="">
                        <p class="font18">持续创新</p>
                    </div>
                    <p class="font14">
                        不断增长的客户需求和研发人数带来持续的创新动力与能力<br/>
                        以华尔街顶尖金融科技为赶超目标，不断升级适合中国金融市场的产品与技术
                    </p>
                </div>
            </div>
        </div> -->

        <!-- 团队介绍 -->
        <div class="section mt4">
            <div class="center">
                <CornerTitle lt-text="03" rb-text="团队介绍" />
                <div class="section4">
                    <img src="../../assets/cover2.jpg" class="part1" />
                    <div class="part2">
                        <p class="font18">智君科技</p>
                        <p class="font14 indent">拥有一大批有着国际顶尖金融机构从业经验和名牌大学研究生以上学历的金融量化与人工智能的工程师，以多年在买方机构的实操经验、华尔街一流标准和技术领先同业竞争者。</p>
                        <p class="font14 indent">公司已面向多家银行、证券公司、基金公司等金融机构提供和交付符合国际行业水准的自有软件产品和项目开发服务。</p>
                        <!-- <p class="font18">研发团队</p>
                        <p class="font14">
                            金融量化—国内外985重点高校的的硕士、博士<br/>
                            IT开发—原道富银行科技部和蚂蚁金服架构师领队<br/>
                            行业专家—依托国内外高校和中国国际风险研究院，转换持续创新的科研成果
                        </p> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="section mt5">
            <div class="center">
                <CornerTitle lt-text="04" rb-text="企业资质" />
                <div class="section5">
                    <div class="flex-row">
                        <img src="../../assets/permit/permit1.png" alt="">
                        <img src="../../assets/permit/permit2.png" alt="">
                        <img src="../../assets/permit/permit6.png" alt="">
                        <!-- <img src="../../assets/permit/permit4.png" alt=""> -->
                        <img src="../../assets/permit/permit5.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CornerTitle from '../../components/CornerTitle'

export default {
    components: { CornerTitle },
    data() {
        return {
            
        }
    }
}
</script>

<style lang="less" scoped>
.content{
    width: 100%;
    max-width: 100%;
}
.center{
    margin: 0 auto;
    max-width: 1200px;
}
.mt1{
    margin-top: 118px;
}
.mt2{
    margin-top: 220px;
}
.mt3{
    // display: none;
    margin-top: 108px;
}
.mt4{
    margin-top: 150px;
}

.section1{
    position: relative;
    height: 440px;
    .part1{
        position: absolute;
        left: 0px;
        top: -66px;
        width: 474px;
    }
    .part2{
        position: absolute;
        right: 0;
        top: 52px;
        width: 815px;
        // height: 42px;
        box-shadow: -12px 14px 6px 0px rgba(9,0,0,0.1);
        padding: 28px;
    }
}
.section1 .part2, .section4 .part2{
    background: #F6F6F6;
    box-sizing: border-box;
    color: #000000;
    text-align: left;
    .font18{
        line-height: 25px;
    }
    .font18:not(:first-of-type){
         margin-top: 20px;
    }
    .font14{
        line-height: 22px;
    }
    .indent{
        text-indent: 2em;
    }
}

.section2{
    display: flex;
    justify-content: space-evenly;
    margin-top: 40px;
    .item{
        width: 200px;
        height: 360px;
        border: 1px solid #666666;
        .circle{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background: #E1E4EE;
            margin: 35px auto;
            position: relative;
            overflow: hidden;
            .word{
                position: absolute;
                font-family: PingFang SC;
                font-weight: 100;
                &.b1, &.b2{
                    line-height: 62px;
                }
                &.b1{
                    top: 0;
                    left: 0;
                }
                &.b2{
                    right: 0;
                    bottom: 0;
                }
                &.s1, &.s2, &.s3{
                    line-height: 54px;
                }
                &.s1{
                    top: 0;
                    left: 5px;
                }
                &.s2{
                    top: 25px;
                    left: 30px;
                }
                &.s3{
                    right: 0;
                    bottom: 0
                }
            }
        }
        p{
            display: inline-block;
            vertical-align: top;
            font-size: 16px;
            width: 16px;
            line-height: 18px;
            word-wrap: break-word;
            margin-right: 12px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
}
   
.section3{
    overflow: hidden;
    color: #002A54;
    margin-top: 100px;
    .item{
        float: left;
        width: 400px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon-box{
            height: 130px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            .font18{
                margin: 30px 0 10px 0;
            }
        }
        .font14{
            height: 122px;
            box-sizing: border-box;
            text-align: left;
            line-height: 24px;
            padding: 18px 66px 0 66px;
            border-left: 1px solid #999999;
        }
        &:first-child .font14{
            border-left: 0;
        }
    }
}

.section4{
    position: relative;
    height: 490px;
    margin: 80px 0;
    .part1{
        position: absolute;
        right: 0;
        top: 37px;
        width: 563px;
    }
    .part2{
        position: absolute;
        left: 0;
        top: 0;
        width: 738px;
        height: 230px;
        box-shadow: 12px 12px 6px 0px rgba(9,0,0,0.1);
        padding: 34px 96px;
    }
}

.section5{
    position: relative;
    height: 300px;
    margin: 80px 0;
    .flex-row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
            width: auto;
            height: 285px;
        }
    }
}
.mt2,.mt5{
    padding: 40px 0;
    background: #fafafa;
}

.font14{
    font-size: 14px;
}
.font18{
    font-size: 18px;
    font-weight: bold;
}
.font36{
    font-size: 36px;
}
.font52{
    font-size: 52px;
}
</style>