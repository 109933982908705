<template>
    <div class="container" id="contact">
        <div class="address">
            <img src="../../assets/home/address.png" alt="">
            <p class="text">海创园5号楼</p>
            <div class="info-box">
                <p class="font30">联系我们</p>
                <p class="font16">
                    <img class="location" src="../../assets/about/icon4.png" alt="">
                    浙江，杭州文一西路998号海创园5号楼603
                </p>
                <p class="font16">
                    <img class="phone" src="../../assets/about/icon5.png" alt="">
                    0571-8617 0015
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    }
}
</script>

<style lang="less" scoped>
.container{
    height: 678px;
    background: url('../../assets/address.jpg') no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
}
.address{
    position: absolute;
    width: 51px;
    top: 220px;
    left: 50%;
    margin-left: -200px;
    img{
        width: 100%;
    }
    .text{
        position: absolute;
        width: 150px;
        top: 100%;
        right: 0;
        font-size: 24px;
        font-weight: bold;
        color: #002A54;
        text-align: right;
    }
    .info-box{
        position: absolute;
        width: 640px;
        left: 100%;
        margin-left: 14px;
        top: -55px;
        background: url('../../assets/arrow-bg.png') no-repeat;
        background-size: cover;
        padding: 36px 86px;
        box-sizing: border-box;
        text-align: left;
        .font30{
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 10px;
        }
        .font16{
            font-size: 16px;
            line-height: 40px;
        }
        img.location{
            width: 16px;
            vertical-align: text-bottom;
            margin-right: 6px;
        }
        img.phone{
            width: 18px;
            margin-right: 6px;
        }
    }
}
</style>