<template>
    <div class="page">
        <Part1 style="margin:0 auto"/>
        <Part2 />
        <Part3 />
    </div>
</template>

<script>
import Part1 from './part1'
import Part2 from './part2'
import Part3 from './part3'

export default {
    components: { Part1, Part2, Part3 },
    data() {
        return {
            
        }
    },
    mounted() {
        if (!this.$route.query.id) return
        let location = this.getTop()
        document.body.scrollTop = location // chrome
        document.documentElement.scrollTop = location // firefox
    },
    methods: {
        getTop() {
            let {id} = this.$route.query
            let anchor = this.$el.querySelector('#' + id)
            return anchor.offsetTop
        }
    }
}
</script>

<style lang="less" scoped>
    
</style>